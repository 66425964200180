:root {
  --rsbs-backdrop-bg: transparency;
  --rsbs-bg: #000000;
  --rsbs-handle-bg: transparency;
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

[data-rsbs-header] {
  display: none;
}

[data-rsbs-footer] {
  display: none;
}
